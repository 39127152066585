:root {
  --customPrimary: #f2f2f2;
  --customBlue: #0063a0;
  --lightblue: #e6ffff;
  --textblue: #006bb3;
  --customGreen: #00cc7a;
}
html {
  box-sizing: border-box;
  font-family: "Trebuchet MS", Arial, Helvetica, sans-serif;
  color: #006bb3;
  color: var(--textblue);
}

body {
  color: #0063a0 !important;
  margin: 30px 50px !important;
  line-height: 1.4 !important;
}
.gandingMap {
  /* margin: auto; */
  background-color: #d0cfd4;
}
.gandingMap path {
  fill: #efefef;
  stroke: #000000;
  stroke-width: 90px;
  -webkit-transition: fill 0.3s;
  transition: fill 0.3s;
}
.odr path {
  fill: #aeb1b1;
}
.odp path {
  fill: #4cc0c0;
}
.pdp path {
  fill: #ff9f40;
}
.confirm path {
  fill: #ff5050;
}
.gandingMap :hover {
  fill: #000000;
}
g.desa :hover text.namaDesa {
  fill: #ffffff;
}

/* .gandingMap :hover text {
 
  fill: #ffffff;
} */

a {
  text-decoration: none;
}
.mainindex {
  color: #006bb3;
  color: var(--textblue);
  /* min-height: 600px; */
  height: 600px;
  background: url(/static/media/main.cde32d95.jpg) no-repeat center center fixed;
  background-size: cover;
  padding: 5rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.mainindex h1 {
  margin: 0;
  text-align: center;
  font-size: 3rem;
}
.mainindex h3 {
  margin: 0;
  text-align: center;
  font-size: 2rem;
}
.mainindex h5 {
  margin: 0;
  text-align: center;
  font-size: 1rem;
  padding: 60px;
}
.fasilitas {
  background: #e6ffff;
  background: var(--lightblue);
  text-align: center;
  padding: 25px;
  margin: 25px 0px;
}
/* .fasilitas p {
  padding: 0 220px;
} */
.fasilitas ul {
  list-style: none;
  display: grid;
  padding: 0;
  /* grid-gap: 20px; */
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
}
.fasilitas img {
  width: 40px;
}
.readMore {
  padding: 15px 30px;
  color: var(--white);
  background-color: #00cc7a;
  background-color: var(--customGreen);
}
.footer {
  background: #0063a0;
  background: var(--customBlue);
  display: flex;
  padding: 0px;
  color: var(--white);
  margin: 0;
}
.footer ul {
  display: flex;
  /* grid-gap: 0px 20px; */
  padding: 0;
  list-style: none;
  /* grid-template-columns: repeat(2, 1fr); */
}
.footer li {
  padding: 0px 75px;
}
.footer h4 {
  margin: 8px 0;
}
.footer p {
  margin: 0;
}
.footer img {
  float: bottom;
  width: 25px;
}

.upayaPelayanan {
  height: auto;
  font-size: 15px;
  background: #e6ffff;
  background: var(--lightblue);
  display: grid;
  cursor: pointer;
}
.upayaPelayanan ul li ul {
  display: none;
}
.upayaPelayanan a {
  text-decoration: none;
  color: #0063a0;
  color: var(--customBlue);
  /* font-size: 10px; */
}
#form {
  font-family: "Trebuchet MS", Arial, Helvetica, sans-serif;
  border-collapse: collapse;
  width: 100%;
  background: #f2f2f2;
  background: var(--customPrimary);
  margin: auto;
}

#form td,
#form th {
  border: 1px solid #ddd;
  padding: 8px;
}

#form tr:nth-child(even) {
  background-color: #f2f2f2;
}

#form tr:hover {
  background-color: #ddd;
}

#form th {
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: center;
  background-color: #0063a0;
  background-color: var(--customBlue);
  color: white;
}
.navbar {
  background-color: #0063a0;
  background-color: var(--customBlue);
}
.navbar-collapse {
  flex-grow: 0 !important;
}
.navbar-brand {
  font-weight: bold;
  color: var(--white) !important;
}
.nav-link {
  color: var(--white) !important;
}
.nav-item {
  font-size: 1.2rem;
}

iframe {
  display: inherit;
  margin: 0 auto;
}
.team {
  width: -webkit-min-content;
  width: -moz-min-content;
  width: min-content;
  margin: 0 auto;
}
.team img {
  width: 265px;
}
@media (max-width: 400px) {
  .team img {
    width: 150px;
  }
}
.team h5 {
  text-align: center;
}
p.font-9 {
  font-size: 9pt;
  margin: 0;
}
.our-team-wrapper {
  margin: 1% 3%;
}
.our-team-wrapper2 {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
}
/*contactUs*/
.contact {
  padding: 60px 0;
}

.contact form {
  margin: 0 auto;
  max-width: 520px;
}
/*login*/
.Login {
  padding: 60px 0;
}

.Login form {
  margin: 0 auto;
  max-width: 320px;
}
.charts {
  display: grid;
  grid-template-columns: 1fr 1fr;
}
.chart {
  margin: auto;
}
progress {
  width: inherit;
}
/* tarif */
.tarif {
  width: 800px;
  margin: 0 auto;
}
th {
  text-align: center;
}
/* .loading {
  position: fixed;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  background-color: rgba(255, 255, 255, 0.75);
  z-index: 10;
}
.loading-content {
  position: absolute;
  top: 50%;
  left: 50%;
  right: auto;
  bottom: auto;
  border: 1px solid rgb(204, 204, 204);
  background: rgb(255, 255, 255);
  overflow: auto;
  border-radius: 4px;
  outline: none;
  padding: 20px;
  margin-right: -50%;
  transform: translate(-50%, -50%);
} */

@media (max-width: 1000px) {
  body {
    margin: 2px 6px !important;
  }
  .charts {
    display: grid !important;
    grid-template-columns: 1fr !important;
  }
  .mainindex {
    padding: 0 !important;
  }
  .footer ul {
    display: grid !important;
    grid-template-columns: repeat(2, 1fr) !important;
    margin: auto !important;
  }
  .footer li {
    margin: 0 8px !important;
    padding: 0.1rem !important;
  }
  .footer h4 {
    font-size: 1rem !important;
  }
  .footer p {
    font-size: 0.8rem !important;
  }

  /* profil */
  .our-team-wrapper2 {
    display: grid !important;
    grid-template-columns: repeat(2, minmax(200px, 1fr)) !important;
  }
}
.mr-auto,
.mx-auto {
  margin-left: auto !important;
  margin-right: 0 !important;
}
@media (max-width: 600px) {
  body {
    margin: 0 !important;
    font-size: 0.8rem !important; /* Tambahan*/
  }
  a {
    font-size: 90% !important;
  }
  h4,
  h5,
  h6 {
    font-size: 100% !important;
  }
  h1 {
    font-size: 200% !important;
  }
  h2 {
    font-size: 140% !important;
  }
  h3 {
    font-size: 140% !important;
  }
  .charts {
    display: grid !important;
    grid-template-columns: 1fr !important;
  }
  .our-team-wrapper2 {
    display: grid;
    grid-template-columns: repeat(1, minmax(200px, 1fr));
  }
  .navbar-brand {
    font-size: 1rem !important;
  }
  .navbar li {
    font-size: 0.9rem !important;
  }
  .mainindex {
    padding: 0 !important;
  }
  .mainindex h1 {
    font-size: 2rem !important;
  }
  .mainindex h3 {
    font-size: 1.5rem !important;
  }
  .mainindex h5 {
    font-size: 0.8rem !important;
  }
  .footer ul {
    display: grid !important;
    grid-template-columns: 1fr !important;
    margin: auto !important;
  }
  .footer li {
    margin: 0 !important;
    padding: 0.1rem !important;
  }
  .footer p {
    font-size: 0.75rem !important; /* Tambahan*/
  }
  iframe {
    width: 290px !important;
    margin: 0 auto !important;
    font-size: 0.8rem !important;
  }
  nav {
    padding: 4px 4px !important;
  }
  #image-upload {
    width: 350px !important;
  }
  progress {
    width: inherit !important;
  }
  .tarif {
    width: 360px !important;
    margin: 0 auto !important;
  }
  /* Tambahan*/
  .navbar {
    align-items: baseline !important;
  }
  nav img {
    width: 45px !important;
    /* display: none; */
  }
  .navbar-toggler {
    width: 32px !important;
    padding: 0 !important;
  }
  .navbar-toggler-icon {
    width: 19px !important;
  }
  .form > h1 {
    font-size: 25px !important;
  }
  .mainindex {
    height: 400px !important;
  }
  .freebirdFormviewerViewHeaderTitle {
    font-size: 0.8rem !important;
  }
  .contact {
    width: 290px !important;
    margin: 0 auto !important;
    padding: 30px 0 !important;
  }
  .tarif {
    width: 290px !important;
  }
  .page-header {
    display: none !important;
  }
  #image-upload {
    width: 290px !important;
  }
  input {
    font-size: 0.8rem !important;
  }

  select {
    font-size: 0.8rem !important;
  }
  .ReactModalPortal > .form-group {
    margin: 0 !important;
  }
  .team img {
    width: 180px;
  }
}
/* Loading */
.lds-spinner {
  color: official;
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.lds-spinner div {
  -webkit-transform-origin: 40px 40px;
          transform-origin: 40px 40px;
  -webkit-animation: lds-spinner 1.2s linear infinite;
          animation: lds-spinner 1.2s linear infinite;
}
.lds-spinner div:after {
  content: " ";
  display: block;
  position: absolute;
  top: 3px;
  left: 37px;
  width: 6px;
  height: 18px;
  border-radius: 20%;
  background: #0063a0;
}
.lds-spinner div:nth-child(1) {
  -webkit-transform: rotate(0deg);
          transform: rotate(0deg);
  -webkit-animation-delay: -1.1s;
          animation-delay: -1.1s;
}
.lds-spinner div:nth-child(2) {
  -webkit-transform: rotate(30deg);
          transform: rotate(30deg);
  -webkit-animation-delay: -1s;
          animation-delay: -1s;
}
.lds-spinner div:nth-child(3) {
  -webkit-transform: rotate(60deg);
          transform: rotate(60deg);
  -webkit-animation-delay: -0.9s;
          animation-delay: -0.9s;
}
.lds-spinner div:nth-child(4) {
  -webkit-transform: rotate(90deg);
          transform: rotate(90deg);
  -webkit-animation-delay: -0.8s;
          animation-delay: -0.8s;
}
.lds-spinner div:nth-child(5) {
  -webkit-transform: rotate(120deg);
          transform: rotate(120deg);
  -webkit-animation-delay: -0.7s;
          animation-delay: -0.7s;
}
.lds-spinner div:nth-child(6) {
  -webkit-transform: rotate(150deg);
          transform: rotate(150deg);
  -webkit-animation-delay: -0.6s;
          animation-delay: -0.6s;
}
.lds-spinner div:nth-child(7) {
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
  -webkit-animation-delay: -0.5s;
          animation-delay: -0.5s;
}
.lds-spinner div:nth-child(8) {
  -webkit-transform: rotate(210deg);
          transform: rotate(210deg);
  -webkit-animation-delay: -0.4s;
          animation-delay: -0.4s;
}
.lds-spinner div:nth-child(9) {
  -webkit-transform: rotate(240deg);
          transform: rotate(240deg);
  -webkit-animation-delay: -0.3s;
          animation-delay: -0.3s;
}
.lds-spinner div:nth-child(10) {
  -webkit-transform: rotate(270deg);
          transform: rotate(270deg);
  -webkit-animation-delay: -0.2s;
          animation-delay: -0.2s;
}
.lds-spinner div:nth-child(11) {
  -webkit-transform: rotate(300deg);
          transform: rotate(300deg);
  -webkit-animation-delay: -0.1s;
          animation-delay: -0.1s;
}
.lds-spinner div:nth-child(12) {
  -webkit-transform: rotate(330deg);
          transform: rotate(330deg);
  -webkit-animation-delay: 0s;
          animation-delay: 0s;
}
@-webkit-keyframes lds-spinner {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@keyframes lds-spinner {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
/* Hide Arrows From Input Number */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input[type="number"] {
  -moz-appearance: textfield;
}
/* Hide Arrows From Input Number */
/* Tambahan */
ul {
  margin: 0 0 15px 0;
  padding-left: 15px;
  list-style: square outside none;
}
.st-post-details.st-style1 {
  font-weight: 300;
  font-size: 18px;
  line-height: 1.8em;

  .slick-slide {
    line-height: 0;
  }

  .st-post-thumb {
    margin-bottom: 25px;

    img {
      width: 100%;
    }
  }

  .st-post-title {
    font-size: 28px;
    margin-bottom: 9px;
  }

  blockquote {
    background-color: $black5;
    margin: 0;
    padding: 30px 40px 27px;
    font-size: 24px;
    line-height: 1.6em;
    border-left: 5px solid $blue;
    color: $black1;
    border-radius: 7px;
    font-weight: 400;
    margin: 20px 0;

    small {
      display: block;
      line-height: inherit;
      margin-top: 4px;
      color: $black3;
      font-size: inherit;
      font-size: 16px;

      span {
        color: $blue;
      }
    }
  }

  .st-post-label {
    margin-bottom: 15px;
  }

  .st-post-text p {
    margin-bottom: 15px;
  }

  .st-post-meta {
    display: flex;
    justify-content: space-between;
    padding: 25px 0;
    line-height: 1.6em;
    font-size: 15px;
    border-top: 1px solid $black4;
    border-bottom: 1px solid $black4;
  }

  h2 {
    font-size: 28px;
    margin-bottom: 10px;
  }

  p {
    margin-bottom: 15px;
  }

  img {
    margin-bottom: 20px;
    border-radius: 7px;
    margin-top: 5px;
  }

  .embed-responsive {
    margin-top: 15px;
    margin-bottom: 15px;
  }

  &.st-color1 {
    blockquote {
      border-left-color: $light-blue;

      small {
        span {
          color: $light-blue;
        }
      }
    }
  }
  &.st-color2 {
    blockquote {
      border-left-color: $pink;

      small {
        span {
          color: $pink;
        }
      }
    }
  }
}

/*
Template Name: Callie HTML Template
Author: yaminncco

Colors:
	Body 		  : #505050
	Headers 	: #11151c
	Primary 	: #ee4266
	Dark 		  : #1b1c1e
	Grey 		  : #e8eaed #97989b #323335

Fonts: Montserrat & Muli

Table OF Contents
------------------------------------
GENERAL
NAVIGATION
PAGE HEADER
POST
POST PAGE
ASIDE
FOOTER
RESPONSIVE
------------------------------------*/

/*=========================================================
	GENERAL
===========================================================*/

/*----------------------------*\
	typography
\*----------------------------*/

body {
  /* font-family: 'Muli', sans-serif;
  font-size: 16px;
  color: #505050;
  font-weight: 400; */
  overflow-x: hidden;
}

.news h1,
.news h2,
.news h3,
.news h4,
.news h5,
.news h6 {
  font-family: "Montserrat", sans-serif;
  color: #11151c;
  font-weight: 700;
  margin: 0px 0px 15px;
}

.news h1 {
  font-size: 32.437px;
}

.news h2 {
  font-size: 25.629px;
}

.news h3 {
  font-size: 20.25px;
}

.news h4 {
  font-size: 16px;
}

.news h5 {
}

.news p {
  margin: 0px 0px 20px;
}

.news a {
  color: #11151c;
  -webkit-transition: 0.2s color;
  transition: 0.2s color;
}

.news a:hover,
.news a:focus {
  color: #ee4266;
  text-decoration: none;
  outline: none;
}

::-moz-selection {
  background-color: #ee4266;
  color: #fff;
}

::selection {
  background-color: #ee4266;
  color: #fff;
}

.news ul,
.news ol {
  margin: 0;
  padding: 0;
  list-style: none;
}

.news ul.list-style,
.news ol.list-style {
  padding-left: 15px;
  margin-bottom: 10px;
}

.news ul.list-style {
  list-style-type: disc;
}

.news ol.list-style {
  list-style-type: decimal;
}

.news blockquote.blockquote {
  padding: 30px;
  margin: 0px 0px 20px;
  border: 2px solid #e8eaed;
}

.news blockquote.blockquote:before {
  content: "\F10D";
  font-family: fontAwesome;
  width: 70px;
  height: 70px;
  line-height: 70px;
  text-align: center;
  border-radius: 50%;
  color: #ee4266;
  font-size: 30px;
  background: #fff;
  border: 2px solid #e8eaed;
  float: left;
  margin-right: 10px;
}

.news blockquote.blockquote .blockquote-footer {
  color: #97989b;
}

.news figure {
  margin-bottom: 20px;
}

.news figure > img {
  width: 100%;
}

.news figure.pull-right {
  margin-left: 15px;
}

.news figure.pull-left {
  margin-right: 15px;
}

.news figure > figcaption {
  font-size: 14px;
  text-align: center;
  margin-top: 10px;
  font-style: italic;
  color: #97989b;
}

/*----------------------------*\
	section
\*----------------------------*/

.news .section {
  padding-top: 30px;
}

.news .section-row {
  margin-bottom: 30px;
}

.news .section-title {
  position: relative;
  margin-bottom: 20px;
}

.news .section-title .title {
  position: relative;
  display: inline-block;
  background-color: #fff;
  font-size: 16px;
  text-transform: uppercase;
  margin-top: 0px;
  margin-bottom: 0px;
  padding-right: 10px;
  z-index: 20;
}

.news .section-title:after {
  content: "";
  display: inline-block;
  height: 2px;
  background-color: #e8eaed;
  position: absolute;
  left: 0;
  right: 0;
  top: 10px;
  z-index: 10;
}

/*----------------------------*\
	input
\*----------------------------*/

.news .input {
  width: 100%;
  height: 40px;
  padding: 0px 15px;
  background-color: #fff;
  border-radius: 2px;
  border: 2px solid #e8eaed;
}

.news textarea.input {
  padding: 15px;
  height: 90px;
}

/*----------------------------*\
	button
\*----------------------------*/

.news .primary-button,
.secondary-button {
  display: inline-block;
  padding: 10px 40px;
  border-radius: 2px;
  border: none;
  font-weight: 700;
  font-size: 14px;
  text-transform: uppercase;
  -webkit-transition: 0.2s all;
  transition: 0.2s all;
}

.news .primary-button {
  background-color: #ee4266;
  color: #fff;
  box-shadow: 0px 0px 0px 2px #ee4266 inset;
}

.news .secondary-button {
  background-color: #353535;
  color: #fff;
  box-shadow: 0px 0px 0px 2px #353535 inset;
}

.news .primary-button:hover,
.primary-button:focus {
  background-color: transparent;
  color: #ee4266;
}

.news .secondary-button:hover,
.secondary-button:focus {
  background-color: transparent;
  color: #353535;
}

/*----------------------------*\
	social colors
\*----------------------------*/

.news .social-facebook {
  background: #225b99 !important;
}

.news .social-twitter {
  background: #00adf2 !important;
}

.news .social-google-plus {
  background: #dc4d2d !important;
}

.news .social-pinterest {
  background: #cc2127 !important;
}

.news .social-instagram {
  background: #d341b2 !important;
}

/*=========================================================
	NAVIGATION
===========================================================*/

.news #nav {
  border-bottom: 1px solid #e8eaed;
}

.news #nav:after {
  content: "";
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 90;
  opacity: 0;
  visibility: hidden;
  -webkit-transition: 0.2s all;
  transition: 0.2s all;
}

.news #nav.shadow-active:after {
  opacity: 1;
  visibility: visible;
}

.news #nav-top {
  text-align: center;
}

.news #nav-top > .container {
  position: relative;
}

.news #nav-bottom {
  border-top: 1px solid #e8eaed;
}

/*----------------------------*\
	social
\*----------------------------*/

.news .nav-social {
  float: left;
  padding: 10px 0px;
}

.news .nav-social li {
  display: inline-block;
}

.news .nav-social li a {
  display: block;
  padding: 15px;
  line-height: 20px;
}

/*----------------------------*\
	logo
\*----------------------------*/

.news .nav-logo {
  position: absolute;
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
}

.news .nav-logo .logo {
  display: inline-block;
  line-height: 70px;
  height: 70px;
}

.news .nav-logo .logo > img {
  width: 100%;
  max-height: 70px;
}

/*----------------------------*\
	search
\*----------------------------*/

.news .nav-btns {
  float: right;
  padding: 10px 0px;
}

.news .nav-btns > button {
  width: 50px;
  height: 50px;
  line-height: 50px;
  text-align: center;
  border: none;
  background: transparent;
}

.news #nav-search {
  position: fixed;
  left: 50%;
  -webkit-transform: translate(-50%, 10px);
  transform: translate(-50%, 10px);
  opacity: 0;
  visibility: hidden;
  max-width: 960px;
  width: 100%;
  padding: 60px 5%;
  background: #1b1c1e;
  z-index: 999;
  -webkit-transition: 0.2s all;
  transition: 0.2s all;
}

.news #nav-search.active {
  opacity: 1;
  visibility: visible;
  -webkit-transform: translate(-50%, 0px);
  transform: translate(-50%, 0px);
}

.news #nav-search form .input {
  height: 80px;
  background: transparent;
  border: 2px solid #323335;
  color: #fff;
  font-size: 24px;
  font-weight: 700;
  padding: 15px 25px;
}

/*----------------------------*\
	menu
\*----------------------------*/

.news .nav-menu {
  position: relative;
  text-align: center;
  float: none;
}

.news .nav-menu > li {
  float: none;
  display: inline-block;
}

.news .nav-menu > li > a {
  display: block;
  padding: 25px 15px;
  line-height: 20px;
  font-weight: 700;
  text-transform: uppercase;
}

/*----------------------------*\
	dropdown
\*----------------------------*/

.news .nav-menu li.has-dropdown {
  position: relative;
}

.news .nav-menu li.has-dropdown > a:after {
  font-family: "FontAwesome";
  content: "\F0D7";
  margin-left: 5px;
}

.news .nav-menu li.has-dropdown > .dropdown {
  position: absolute;
  left: 0;
  width: 200px;
  text-align: left;
  z-index: 60;
  -webkit-transform: translateY(10px);
  transform: translateY(10px);
  opacity: 0;
  visibility: hidden;
  -webkit-transition: 0.2s all;
  transition: 0.2s all;
}

.news .nav-menu li.has-dropdown:hover > .dropdown {
  -webkit-transform: translateY(0px);
  transform: translateY(0px);
  opacity: 1;
  visibility: visible;
}

.news .nav-menu li.has-dropdown > .dropdown .dropdown-body {
  background-color: #fff;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
  border-top: 2px solid #ee4266;
}

.news .dropdown .dropdown-list li {
  border-bottom: 1px solid #e8eaed;
}

.news .dropdown .dropdown-list li a {
  display: block;
  padding: 10px;
  line-height: 20px;
}

.news .dropdown .dropdown-heading {
  text-transform: uppercase;
  font-size: 14px;
  margin-top: 0px;
  margin-bottom: 10px;
  line-height: 30px;
}

/*----------------------------*\
	mega dropdown
\*----------------------------*/

.news .nav-menu li.has-dropdown.megamenu {
  position: static;
}

.news .nav-menu li.has-dropdown.megamenu > .dropdown {
  width: 100%;
}

.news .nav-menu li.has-dropdown.megamenu > .dropdown .dropdown-body {
  padding: 30px;
}

.news .nav-menu li.has-dropdown.megamenu > .dropdown .dropdown-list {
  margin-bottom: 30px;
}

.news .nav-menu li.has-dropdown.megamenu > .dropdown .dropdown-list li a {
  padding-left: 0px;
  padding-right: 0px;
}

/*----------------------------*\
	tab dropdown
\*----------------------------*/

.news .nav-menu li.has-dropdown.megamenu > .dropdown.tab-dropdown .tab-content {
  z-index: 70;
  position: relative;
  margin-left: -30px;
}

.news .nav-menu li.has-dropdown.megamenu > .dropdown.tab-dropdown .tab-nav {
  background-color: #1b1c1e;
  box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.1);
  position: relative;
  z-index: 60;
}

.news .nav-menu li.has-dropdown.megamenu > .dropdown.tab-dropdown .tab-nav li {
  border-bottom: 1px solid #323335;
}

.news
  .nav-menu
  li.has-dropdown.megamenu
  > .dropdown.tab-dropdown
  .tab-nav
  li
  a {
  display: block;
  padding: 15px;
  color: #fff;
  font-weight: 700;
  font-size: 12px;
  text-transform: uppercase;
}

.news
  .nav-menu
  li.has-dropdown.megamenu
  > .dropdown.tab-dropdown
  .tab-nav
  li.active {
  background: #ee4266;
}

.news
  .nav-menu
  li.has-dropdown.megamenu
  > .dropdown.tab-dropdown
  .tab-nav
  li.active
  a {
  color: #fff;
}

/*----------------------------*\
	nav aside
\*----------------------------*/

.news #nav-aside {
  position: fixed;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: #1b1c1e;
  max-width: 360px;
  width: 100%;
  padding: 80px 20px;
  overflow-y: scroll;
  z-index: 99;
  -webkit-transform: translateX(100%);
  transform: translateX(100%);
  -webkit-transition: 0.4s all cubic-bezier(0.77, 0, 0.18, 1);
  transition: 0.4s all cubic-bezier(0.77, 0, 0.18, 1);
}

.news #nav-aside.active {
  -webkit-transform: translateX(0%);
  transform: translateX(0%);
}

.news .nav-aside-menu li a {
  display: block;
  padding: 15px 0px;
  color: #fff;
  border-bottom: 1px solid #323335;
}

.news .nav-aside-menu li a:hover,
.nav-aside-menu li a:focus {
  color: #ee4266;
}

.news .nav-aside-menu li.has-dropdown > a {
  cursor: pointer;
}

.news .nav-aside-menu li.has-dropdown > a:after {
  font-family: "FontAwesome";
  content: "\F0D7";
  float: right;
}

.news .nav-aside-menu li.has-dropdown > .dropdown {
  display: none;
  margin-left: 30px;
  border-left: 1px solid #323335;
}

.news .nav-aside-menu li.has-dropdown.active > .dropdown {
  display: block;
}

.news .nav-aside-menu li.has-dropdown > .dropdown a {
  padding: 15px;
}

.news .nav-close {
  width: 50px;
  height: 50px;
  position: absolute;
  top: 10px;
  right: 15px;
  background-color: transparent;
  border: none;
}

.news .nav-close span {
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
}

.news .nav-close span:before,
.nav-close span:after {
  content: "";
  display: block;
  width: 30px;
  background-color: #fff;
  height: 2px;
}

.news .nav-close span:before {
  -webkit-transform: translateY(0px) rotate(-135deg);
  transform: translateY(0px) rotate(-135deg);
}

.news .nav-close span:after {
  -webkit-transform: translateY(-2px) rotate(135deg);
  transform: translateY(-2px) rotate(135deg);
}

/*=========================================================
	PAGE HEADER
===========================================================*/

.news .page-header {
  position: relative;
  margin: 0;
  padding-top: 120px;
  padding-bottom: 80px;
  background-color: #1b1c1e;
}

.news #post-header {
  padding-top: 240px;
  padding-bottom: 80px;
}

.news .page-header .page-header-bg {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.news .page-header .page-header-bg:after {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: -webkit-gradient(
    linear,
    left top,
    left bottom,
    from(rgba(27, 28, 30, 0)),
    color-stop(90%, rgba(27, 28, 30, 0.8))
  );
  background: -webkit-linear-gradient(
    top,
    rgba(27, 28, 30, 0) 0%,
    rgba(27, 28, 30, 0.8) 90%
  );
  background: linear-gradient(
    180deg,
    rgba(27, 28, 30, 0) 0%,
    rgba(27, 28, 30, 0.8) 90%
  );
}

.news .page-header h1 {
  font-size: 41.053px;
  color: #fff;
}

.news .page-header p.lead {
  font-size: 20.25px;
  color: #fff;
}

/*=========================================================
	POST
===========================================================*/

.news .post {
  position: relative;
  margin-bottom: 30px;
}

.news .post .post-body {
  position: relative;
  margin: -50px 0px 0px 3%;
  padding: 5%;
  background: #fff;
  /* z-index: 10; */
}

.news .post .post-img {
  display: block;
  overflow: hidden;
}

.news .post-img > img {
  width: 100%;
  -webkit-transition: 1.6s -webkit-transform;
  transition: 1.6s -webkit-transform;
  transition: 1.6s transform;
  transition: 1.6s transform, 1.6s -webkit-transform;
}

.news .post-img:hover > img {
  -webkit-transform: scale(1.2);
  transform: scale(1.2);
}

.news .post .post-title {
  font-size: 18px;
}

.news .post .post-title.title-sm {
  font-size: 16px;
}

.news .post .post-title.title-lg {
  font-size: 25.629px;
}

.news .post .post-category {
  margin-bottom: 10px;
}

.news .post-category a {
  color: #ee4266;
  font-weight: 700;
  font-size: 12px;
  text-transform: uppercase;
}

.news .post-category a:after {
  content: ",";
  display: inline-block;
}

.news .post-category a:last-child:after {
  display: none;
}

.news .post .post-meta {
  margin-bottom: 10px;
}

.news .post-meta li {
  display: inline-block;
  color: #97989b;
  font-size: 12px;
  text-transform: uppercase;
}

.news .post-meta li a {
  color: #97989b;
  font-weight: 700;
}

.news .post-meta li a:hover,
.post-meta li a:focus {
  color: #ee4266;
}

.news .post-meta li:after {
  content: "\2022";
  display: inline-block;
  padding-left: 5px;
  padding-right: 5px;
}

.news .post-meta li:last-child:after {
  display: none;
}

/*----------------------------*\
	hot post
\*----------------------------*/

.news .hot-post {
  padding: 0 15px;
}

.news .hot-post .hot-post-left {
  padding-right: 0px;
  padding-left: 0px;
}

.news .hot-post .hot-post-right {
  padding-right: 0;
  padding-left: 5px;
}

.news .hot-post .post {
  margin-bottom: 7px;
}

.news .hot-post {
  margin-bottom: 25px;
}

/*----------------------------*\
	post thumb
\*----------------------------*/

.news .post.post-thumb .post-img:after {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: -webkit-gradient(
    linear,
    left top,
    left bottom,
    from(rgba(27, 28, 30, 0)),
    color-stop(90%, rgba(27, 28, 30, 0.8))
  );
  background: -webkit-linear-gradient(
    top,
    rgba(27, 28, 30, 0) 0%,
    rgba(27, 28, 30, 0.8) 90%
  );
  background: linear-gradient(
    180deg,
    rgba(27, 28, 30, 0) 0%,
    rgba(27, 28, 30, 0.8) 90%
  );
}

.post.post-thumb .post-body {
  position: absolute;
  margin: 0px;
  padding: 0px 5%;
  z-index: 20;
  bottom: 5%;
  background-color: transparent;
}

.post.post-thumb .post-title a {
  color: #fff;
}

.post.post-thumb .post-title a:hover,
.post.post-thumb .post-title a:focus {
  color: #ee4266;
}

.post.post-thumb .post-meta li {
  color: #fff;
}

.post.post-thumb .post-meta li a {
  color: #fff;
}

.post.post-thumb .post-meta li a:hover,
.post.post-thumb .post-meta li a:focus {
  color: #ee4266;
}

/*----------------------------*\
	post small
\*----------------------------*/

.post.post-sm .post-body {
  margin: 15px 0px 0px;
  background: transparent;
  padding: 0;
}

/*----------------------------*\
	post widget
\*----------------------------*/

.post.post-widget:after {
  content: "";
  display: table;
  clear: both;
}

.post.post-widget .post-img {
  float: left;
  width: 130px;
}

.post.post-widget .post-body {
  position: relative;
  background: transparent;
  margin: 0px 0px 0px 150px;
  padding: 0;
}

.post.post-widget .post-title {
  font-size: 16px;
  margin-bottom: 0px;
}

/*----------------------------*\
	post row
\*----------------------------*/

.post.post-row:after {
  content: "";
  display: table;
  clear: both;
}

.post.post-row .post-img {
  width: 40%;
  float: left;
}

.post.post-row .post-body {
  margin: 0px 0px 0px calc(40% + 30px);
  padding: 0;
}

/*=========================================================
	POST PAGE
===========================================================*/

.page-header .post-category {
  margin-bottom: 10px;
}

.page-header .post-meta li {
  color: #fff;
}

.page-header .post-meta li a {
  color: #fff;
}

.page-header .post-meta li a:hover,
.page-header .post-meta li a:focus {
  color: #ee4266;
}

.post-share a {
  display: inline-block;
  padding: 5px 15px;
  border-radius: 5px;
  color: #fff;
  background-color: #97989b;
  margin-right: 4px;
  margin-bottom: 6px;
  -webkit-transition: 0.2s opacity;
  transition: 0.2s opacity;
}

.post-share a:hover {
  opacity: 0.9;
}

.post-share a i {
  width: 20px;
  text-align: center;
}

.post-share a span {
  padding-left: 10px;
  border-left: 2px solid rgba(255, 255, 255, 0.1);
  text-transform: uppercase;
  font-size: 12px;
  font-weight: 700;
  margin-left: 10px;
}

.post-content .post-attachement.pull-left {
  margin-right: 15px;
}

.post-content .post-attachement.pull-right {
  margin-left: 15px;
}

.post-tags ul li {
  display: inline-block;
  text-transform: uppercase;
  font-size: 12px;
  font-weight: 700;
}

.post-tags ul li:first-child {
  font-weight: 400;
}

.post-tags ul li:after {
  content: ",";
  display: inline-block;
}

.post-tags ul li:first-child:after {
  display: none;
}

.post-tags ul li:last-child:after {
  display: none;
}

/*----------------------------*\
	post nav
\*----------------------------*/

.post-nav:after {
  content: "";
  display: table;
  clear: both;
}

.post-nav .post-title {
  font-size: 14px;
  margin: 0px;
}

.post-nav .prev-post {
  float: left;
  width: 50%;
  padding-right: 15px;
}

.post-nav .next-post {
  float: right;
  width: 50%;
  text-align: right;
  padding-left: 15px;
}

.post-nav .post-img {
  display: block;
  width: 100px;
  overflow: hidden;
}

.post-nav .prev-post .post-img {
  float: left;
  margin-right: 15px;
}

.post-nav .next-post .post-img {
  float: right;
  margin-left: 15px;
}

.post-nav .next-post span,
.post-nav .prev-post span {
  font-weight: 700;
  color: #97989b;
  text-transform: uppercase;
  font-size: 12px;
}

.post-nav .next-post:after,
.post-nav .prev-post:after {
  content: "";
  display: table;
  clear: both;
}

/*----------------------------*\
	author
\*----------------------------*/

.author.media .media-left {
  position: relative;
  padding-right: 30px;
}

.author .author-img {
  width: 100px;
  border-radius: 50%;
  margin-bottom: 15px;
}

.author .author-social li {
  display: inline-block;
  margin-right: 10px;
}

.author .author-social li a {
  display: block;
}

.page-header .author .author-social li a {
  color: #fff;
}

.page-header .author .author-social li a:hover,
.page-header .author .author-social li a:focus {
  color: #ee4266;
}

/*----------------------------*\
	comments
\*----------------------------*/

.post-comments .media {
  margin-top: 30px;
}

.post-comments .media:nth-child(1) {
  margin-top: 0px;
}

.post-comments .media .media-left {
  position: relative;
  padding-right: 15px;
}

.post-comments .media .media-left .media-object {
  width: 50px;
  border-radius: 50%;
  position: relative;
  z-index: 20;
}

.post-comments .media .media-left:after {
  content: "";
  position: absolute;
  left: calc(50% - 9px);
  top: 80px;
  bottom: 15px;
  width: 1px;
  background-color: #e8eaed;
}

.post-comments .media .media-heading h4 {
  display: inline-block;
  font-size: 14px;
  text-transform: uppercase;
}

.post-comments .media .media-heading .time {
  color: #97989b;
  margin-left: 10px;
  font-size: 12px;
}

.post-comments .media .reply {
  text-transform: uppercase;
  display: inline-block;
  padding: 5px 13px;
  font-size: 12px;
  font-weight: 700;
  color: #fff;
  background-color: #323335;
  border-radius: 2px;
  -webkit-transition: 0.2s opacity;
  transition: 0.2s opacity;
}

.post-comments .media .reply:hover {
  opacity: 0.9;
}

.post-comments .media.media-author .media-heading h4 {
  color: #ee4266;
}

.post-comments .media.media-author .media-left:after {
  background-color: #ee4266;
}

/*=========================================================
	ASIDE
===========================================================*/

.aside-widget {
  margin-bottom: 30px;
}

/*----------------------------*\
	social widget
\*----------------------------*/

.social-widget ul {
  overflow: auto;
}

.social-widget ul li {
  float: left;
  width: 33.33%;
}

.social-widget ul li a {
  display: block;
  text-align: center;
  padding: 30px 15px;
  color: #fff;
  -webkit-transition: 0.2s opacity;
  transition: 0.2s opacity;
}

.social-widget ul li a:hover {
  opacity: 0.9;
}

.social-widget ul li a span {
  font-weight: 700;
  font-size: 14px;
}

.social-widget ul li a i {
  display: block;
  font-size: 30px;
  margin-bottom: 10px;
}

/*----------------------------*\
	newsletter widget
\*----------------------------*/

.newsletter-widget {
  position: relative;
  padding: 0px 30px 30px;
  border-left: 2px dashed #e8eaed;
  border-right: 2px dashed #e8eaed;
}

.aside-widget:last-child .newsletter-widget {
  border-bottom: 2px dashed #e8eaed;
}

.newsletter-widget form {
  position: relative;
  z-index: 20;
}

.newsletter-widget .input {
  margin-bottom: 15px;
}

.newsletter-widget:after {
  content: "\F003";
  font-family: FontAwesome;
  position: absolute;
  bottom: -25px;
  right: 27px;
  font-size: 160px;
  color: #e8eaed;
  -webkit-transform: rotate(13deg);
  transform: rotate(13deg);
}

.footer-widget .newsletter-widget {
  padding: 0px;
  border: none;
}

.footer-widget .newsletter-widget:after {
  color: #323335;
}

/*----------------------------*\
	category widget
\*----------------------------*/

.category-widget ul li {
  padding: 15px 0px;
  border-bottom: 1px solid #e8eaed;
}

.category-widget ul li a {
  display: block;
  text-transform: uppercase;
  font-weight: 700;
}

.category-widget ul li a span {
  float: right;
  font-size: 14px;
  color: #97989b;
  margin-left: 15px;
}

.footer-widget .category-widget ul li {
  border-color: #323335;
}

/*----------------------------*\
	tags widget
\*----------------------------*/

.tags-widget ul li {
  display: inline-block;
  margin-right: 4px;
  margin-bottom: 6px;
}

.tags-widget ul li a {
  text-transform: uppercase;
  display: block;
  padding: 10px 15px;
  font-size: 12px;
  font-weight: 700;
  color: #97989b;
  background-color: #323335;
  border-radius: 2px;
  -webkit-transition: 0.2s all;
  transition: 0.2s all;
}

.tags-widget ul li a:hover,
.tags-widget ul li a:focus {
  color: #fff !important;
  background-color: #ee4266;
}

/*----------------------------*\
	galery widget
\*----------------------------*/

.galery-widget ul {
  margin: -2px -2px;
}

.galery-widget ul li {
  display: inline-block;
  width: 33.33%;
  padding: 2px;
  margin-right: -4px;
}

.galery-widget ul li a {
  display: block;
  overflow: hidden;
}

.galery-widget ul li a img {
  width: 100%;
  -webkit-transition: 1.6s -webkit-transform;
  transition: 1.6s -webkit-transform;
  transition: 1.6s transform;
  transition: 1.6s transform, 1.6s -webkit-transform;
}

.galery-widget ul li a:hover img {
  -webkit-transform: scale(1.2);
  transform: scale(1.2);
}

/*=========================================================
	FOOTER
===========================================================*/

#footer {
  margin-top: 40px;
  padding-top: 80px;
  padding-bottom: 40px;
  background-color: #1b1c1e;
}

#footer {
  color: #97989b;
}

#footer a {
  color: #97989b;
}

#footer a:hover,
#footer a:focus {
  color: #ee4266;
}

.footer-widget {
  margin-bottom: 30px;
}

.footer-widget .footer-title {
  color: #fff;
  font-size: 14px;
  text-transform: uppercase;
}

.footer-logo {
  margin-bottom: 30px;
}

.footer-logo .logo {
  display: inline-block;
  line-height: 70px;
  height: 70px;
}

.footer-bottom {
  margin-top: 30px;
  padding-top: 30px;
  border-top: 1px solid #323335;
}

.footer-nav {
  text-align: right;
}

.footer-nav li {
  display: inline-block;
  margin-right: 10px;
}

/*----------------------------*\
	CONTACT
\*----------------------------*/

.contact li {
  position: relative;
  margin-bottom: 20px;
  padding-left: 55px;
  padding-top: 5px;
  min-height: 40px;
}

.contact li i {
  position: absolute;
  left: 0;
  top: 0;
  width: 40px;
  text-align: center;
  height: 40px;
  background: #fff;
  color: #ee4266;
  border: 1px solid #e8eaed;
  line-height: 40px;
  border-radius: 50%;
}

.footer-widget .contact li i {
  color: #97989b;
  background-color: #353535;
  border: none;
}

.contact-social li {
  display: inline-block;
  margin-right: 3px;
  margin-bottom: 6px;
}

.contact-social li a {
  display: block;
  width: 40px;
  height: 40px;
  line-height: 40px;
  font-size: 14px;
  text-align: center;
  border-radius: 50%;
  color: #fff !important;
  -webkit-transition: 0.2s opacity;
  transition: 0.2s opacity;
}

.contact-social li a:hover {
  opacity: 0.9;
}

/*=========================================================
	RESPONSIVE
===========================================================*/

@media only screen and (max-width: 991px) {
  #nav-bottom {
    display: none;
  }
  .nav-social {
    display: none;
  }
  .nav-logo {
    position: static;
    -webkit-transform: none;
    transform: none;
    left: 0;
    display: inline-block;
    float: left;
  }
  .footer-nav {
    text-align: left;
    margin-bottom: 30px;
  }
}

@media only screen and (max-width: 767px) {
  .page-header h1 {
    font-size: 32.437px;
  }
  .post-nav .prev-post {
    float: none;
    width: 100%;
    margin-bottom: 15px;
    padding-right: 0px;
  }
  .post-nav .prev-post:last-child {
    margin-bottom: 0px;
  }
  .post-nav .next-post {
    float: none;
    width: 100%;
  }
  .post-nav .next-post {
    float: none;
    width: 100%;
    padding-left: 0px;
    text-align: left;
  }
  .post-nav .next-post .post-img {
    margin-right: 15px;
    float: left;
    margin-left: 0px;
  }
}

