:root {
  --customPrimary: #f2f2f2;
  --customBlue: #0063a0;
  --lightblue: #e6ffff;
  --textblue: #006bb3;
  --customGreen: #00cc7a;
}
html {
  box-sizing: border-box;
  font-family: "Trebuchet MS", Arial, Helvetica, sans-serif;
  color: var(--textblue);
}

body {
  color: #0063a0 !important;
  margin: 30px 50px !important;
  line-height: 1.4 !important;
}
.gandingMap {
  /* margin: auto; */
  background-color: #d0cfd4;
}
.gandingMap path {
  fill: #efefef;
  stroke: #000000;
  stroke-width: 90px;
  transition: fill 0.3s;
}
.odr path {
  fill: #aeb1b1;
}
.odp path {
  fill: #4cc0c0;
}
.pdp path {
  fill: #ff9f40;
}
.confirm path {
  fill: #ff5050;
}
.gandingMap :hover {
  fill: #000000;
}
g.desa :hover text.namaDesa {
  fill: #ffffff;
}

/* .gandingMap :hover text {
 
  fill: #ffffff;
} */

a {
  text-decoration: none;
}
.mainindex {
  color: var(--textblue);
  /* min-height: 600px; */
  height: 600px;
  background: url("./Public/img/main.jpg") no-repeat center center fixed;
  background-size: cover;
  padding: 5rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.mainindex h1 {
  margin: 0;
  text-align: center;
  font-size: 3rem;
}
.mainindex h3 {
  margin: 0;
  text-align: center;
  font-size: 2rem;
}
.mainindex h5 {
  margin: 0;
  text-align: center;
  font-size: 1rem;
  padding: 60px;
}
.fasilitas {
  background: var(--lightblue);
  text-align: center;
  padding: 25px;
  margin: 25px 0px;
}
/* .fasilitas p {
  padding: 0 220px;
} */
.fasilitas ul {
  list-style: none;
  display: grid;
  padding: 0;
  /* grid-gap: 20px; */
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
}
.fasilitas img {
  width: 40px;
}
.readMore {
  padding: 15px 30px;
  color: var(--white);
  background-color: var(--customGreen);
}
.footer {
  background: var(--customBlue);
  display: flex;
  padding: 0px;
  color: var(--white);
  margin: 0;
}
.footer ul {
  display: flex;
  /* grid-gap: 0px 20px; */
  padding: 0;
  list-style: none;
  /* grid-template-columns: repeat(2, 1fr); */
}
.footer li {
  padding: 0px 75px;
}
.footer h4 {
  margin: 8px 0;
}
.footer p {
  margin: 0;
}
.footer img {
  float: bottom;
  width: 25px;
}

.upayaPelayanan {
  height: auto;
  font-size: 15px;
  background: var(--lightblue);
  display: grid;
  cursor: pointer;
}
.upayaPelayanan ul li ul {
  display: none;
}
.upayaPelayanan a {
  text-decoration: none;
  color: var(--customBlue);
  /* font-size: 10px; */
}
#form {
  font-family: "Trebuchet MS", Arial, Helvetica, sans-serif;
  border-collapse: collapse;
  width: 100%;
  background: var(--customPrimary);
  margin: auto;
}

#form td,
#form th {
  border: 1px solid #ddd;
  padding: 8px;
}

#form tr:nth-child(even) {
  background-color: #f2f2f2;
}

#form tr:hover {
  background-color: #ddd;
}

#form th {
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: center;
  background-color: var(--customBlue);
  color: white;
}
.navbar {
  background-color: var(--customBlue);
}
.navbar-collapse {
  flex-grow: 0 !important;
}
.navbar-brand {
  font-weight: bold;
  color: var(--white) !important;
}
.nav-link {
  color: var(--white) !important;
}
.nav-item {
  font-size: 1.2rem;
}

iframe {
  display: inherit;
  margin: 0 auto;
}
.team {
  width: min-content;
  margin: 0 auto;
}
.team img {
  width: 265px;
}
@media (max-width: 400px) {
  .team img {
    width: 150px;
  }
}
.team h5 {
  text-align: center;
}
p.font-9 {
  font-size: 9pt;
  margin: 0;
}
.our-team-wrapper {
  margin: 1% 3%;
}
.our-team-wrapper2 {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
}
/*contactUs*/
.contact {
  padding: 60px 0;
}

.contact form {
  margin: 0 auto;
  max-width: 520px;
}
/*login*/
.Login {
  padding: 60px 0;
}

.Login form {
  margin: 0 auto;
  max-width: 320px;
}
.charts {
  display: grid;
  grid-template-columns: 1fr 1fr;
}
.chart {
  margin: auto;
}
progress {
  width: inherit;
}
/* tarif */
.tarif {
  width: 800px;
  margin: 0 auto;
}
th {
  text-align: center;
}
/* .loading {
  position: fixed;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  background-color: rgba(255, 255, 255, 0.75);
  z-index: 10;
}
.loading-content {
  position: absolute;
  top: 50%;
  left: 50%;
  right: auto;
  bottom: auto;
  border: 1px solid rgb(204, 204, 204);
  background: rgb(255, 255, 255);
  overflow: auto;
  border-radius: 4px;
  outline: none;
  padding: 20px;
  margin-right: -50%;
  transform: translate(-50%, -50%);
} */

@media (max-width: 1000px) {
  body {
    margin: 2px 6px !important;
  }
  .charts {
    display: grid !important;
    grid-template-columns: 1fr !important;
  }
  .mainindex {
    padding: 0 !important;
  }
  .footer ul {
    display: grid !important;
    grid-template-columns: repeat(2, 1fr) !important;
    margin: auto !important;
  }
  .footer li {
    margin: 0 8px !important;
    padding: 0.1rem !important;
  }
  .footer h4 {
    font-size: 1rem !important;
  }
  .footer p {
    font-size: 0.8rem !important;
  }

  /* profil */
  .our-team-wrapper2 {
    display: grid !important;
    grid-template-columns: repeat(2, minmax(200px, 1fr)) !important;
  }
}
.mr-auto,
.mx-auto {
  margin-left: auto !important;
  margin-right: 0 !important;
}
@media (max-width: 600px) {
  body {
    margin: 0 !important;
    font-size: 0.8rem !important; /* Tambahan*/
  }
  a {
    font-size: 90% !important;
  }
  h4,
  h5,
  h6 {
    font-size: 100% !important;
  }
  h1 {
    font-size: 200% !important;
  }
  h2 {
    font-size: 140% !important;
  }
  h3 {
    font-size: 140% !important;
  }
  .charts {
    display: grid !important;
    grid-template-columns: 1fr !important;
  }
  .our-team-wrapper2 {
    display: grid;
    grid-template-columns: repeat(1, minmax(200px, 1fr));
  }
  .navbar-brand {
    font-size: 1rem !important;
  }
  .navbar li {
    font-size: 0.9rem !important;
  }
  .mainindex {
    padding: 0 !important;
  }
  .mainindex h1 {
    font-size: 2rem !important;
  }
  .mainindex h3 {
    font-size: 1.5rem !important;
  }
  .mainindex h5 {
    font-size: 0.8rem !important;
  }
  .footer ul {
    display: grid !important;
    grid-template-columns: 1fr !important;
    margin: auto !important;
  }
  .footer li {
    margin: 0 !important;
    padding: 0.1rem !important;
  }
  .footer p {
    font-size: 0.75rem !important; /* Tambahan*/
  }
  iframe {
    width: 290px !important;
    margin: 0 auto !important;
    font-size: 0.8rem !important;
  }
  nav {
    padding: 4px 4px !important;
  }
  #image-upload {
    width: 350px !important;
  }
  progress {
    width: inherit !important;
  }
  .tarif {
    width: 360px !important;
    margin: 0 auto !important;
  }
  /* Tambahan*/
  .navbar {
    align-items: baseline !important;
  }
  nav img {
    width: 45px !important;
    /* display: none; */
  }
  .navbar-toggler {
    width: 32px !important;
    padding: 0 !important;
  }
  .navbar-toggler-icon {
    width: 19px !important;
  }
  .form > h1 {
    font-size: 25px !important;
  }
  .mainindex {
    height: 400px !important;
  }
  .freebirdFormviewerViewHeaderTitle {
    font-size: 0.8rem !important;
  }
  .contact {
    width: 290px !important;
    margin: 0 auto !important;
    padding: 30px 0 !important;
  }
  .tarif {
    width: 290px !important;
  }
  .page-header {
    display: none !important;
  }
  #image-upload {
    width: 290px !important;
  }
  input {
    font-size: 0.8rem !important;
  }

  select {
    font-size: 0.8rem !important;
  }
  .ReactModalPortal > .form-group {
    margin: 0 !important;
  }
  .team img {
    width: 180px;
  }
}
/* Loading */
.lds-spinner {
  color: official;
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.lds-spinner div {
  transform-origin: 40px 40px;
  animation: lds-spinner 1.2s linear infinite;
}
.lds-spinner div:after {
  content: " ";
  display: block;
  position: absolute;
  top: 3px;
  left: 37px;
  width: 6px;
  height: 18px;
  border-radius: 20%;
  background: #0063a0;
}
.lds-spinner div:nth-child(1) {
  transform: rotate(0deg);
  animation-delay: -1.1s;
}
.lds-spinner div:nth-child(2) {
  transform: rotate(30deg);
  animation-delay: -1s;
}
.lds-spinner div:nth-child(3) {
  transform: rotate(60deg);
  animation-delay: -0.9s;
}
.lds-spinner div:nth-child(4) {
  transform: rotate(90deg);
  animation-delay: -0.8s;
}
.lds-spinner div:nth-child(5) {
  transform: rotate(120deg);
  animation-delay: -0.7s;
}
.lds-spinner div:nth-child(6) {
  transform: rotate(150deg);
  animation-delay: -0.6s;
}
.lds-spinner div:nth-child(7) {
  transform: rotate(180deg);
  animation-delay: -0.5s;
}
.lds-spinner div:nth-child(8) {
  transform: rotate(210deg);
  animation-delay: -0.4s;
}
.lds-spinner div:nth-child(9) {
  transform: rotate(240deg);
  animation-delay: -0.3s;
}
.lds-spinner div:nth-child(10) {
  transform: rotate(270deg);
  animation-delay: -0.2s;
}
.lds-spinner div:nth-child(11) {
  transform: rotate(300deg);
  animation-delay: -0.1s;
}
.lds-spinner div:nth-child(12) {
  transform: rotate(330deg);
  animation-delay: 0s;
}
@keyframes lds-spinner {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
/* Hide Arrows From Input Number */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input[type="number"] {
  -moz-appearance: textfield;
}
/* Hide Arrows From Input Number */
/* Tambahan */
ul {
  margin: 0 0 15px 0;
  padding-left: 15px;
  list-style: square outside none;
}
.st-post-details.st-style1 {
  font-weight: 300;
  font-size: 18px;
  line-height: 1.8em;

  .slick-slide {
    line-height: 0;
  }

  .st-post-thumb {
    margin-bottom: 25px;

    img {
      width: 100%;
    }
  }

  .st-post-title {
    font-size: 28px;
    margin-bottom: 9px;
  }

  blockquote {
    background-color: $black5;
    margin: 0;
    padding: 30px 40px 27px;
    font-size: 24px;
    line-height: 1.6em;
    border-left: 5px solid $blue;
    color: $black1;
    border-radius: 7px;
    font-weight: 400;
    margin: 20px 0;

    small {
      display: block;
      line-height: inherit;
      margin-top: 4px;
      color: $black3;
      font-size: inherit;
      font-size: 16px;

      span {
        color: $blue;
      }
    }
  }

  .st-post-label {
    margin-bottom: 15px;
  }

  .st-post-text p {
    margin-bottom: 15px;
  }

  .st-post-meta {
    display: flex;
    justify-content: space-between;
    padding: 25px 0;
    line-height: 1.6em;
    font-size: 15px;
    border-top: 1px solid $black4;
    border-bottom: 1px solid $black4;
  }

  h2 {
    font-size: 28px;
    margin-bottom: 10px;
  }

  p {
    margin-bottom: 15px;
  }

  img {
    margin-bottom: 20px;
    border-radius: 7px;
    margin-top: 5px;
  }

  .embed-responsive {
    margin-top: 15px;
    margin-bottom: 15px;
  }

  &.st-color1 {
    blockquote {
      border-left-color: $light-blue;

      small {
        span {
          color: $light-blue;
        }
      }
    }
  }
  &.st-color2 {
    blockquote {
      border-left-color: $pink;

      small {
        span {
          color: $pink;
        }
      }
    }
  }
}
